import React from "react";
import { Outlet } from "react-router-dom";

const phoneIcon = new URL("../assets/images/phone-icon.svg", import.meta.url);
const logoFullDark = new URL(
  "../assets/images/logo-full-dark.svg",
  import.meta.url
);
import CtmPhone from "./CtmPhone";
import Header from "./Header";

export default function LayoutHomepage() {
  return (
    <main
      className="relative flex flex-col flex-grow bg-dark-blue bg-main bg-no-repeat bg-[left_top] lg:bg-[left_top_-50px] xl:bg-[left_top_-100px]"
      style={{ backgroundSize: "100% auto" }}
    >
      <Header />
      <div className="container flex flex-col flex-grow mx-auto mb-10 px-3 sm:px-5 pt-[100px] lg:pt-[200px] xl:pt-[260px] 2xl:pt-[320px] lg:mb-20">
        <Outlet />
      </div>
      <div className="bg-white py-5 lg:py-10">
        <div className="container mx-auto bg-white text-dark-blue flex flex-col lg:flex-row justify-between items-center px-3 sm:px-5">
          <img
            src={logoFullDark.href}
            alt="Fenix"
            className="cursor-pointer h-fit max-w-none mb-3 lg:mb-0"
            width={216}
            height={60}
            onClick={() =>
              (window.location.href = "https://fenixfuneral.co.uk/")
            }
          />
          <div>
            <div className="text-dark-blue font-pprightgothic text-[30px] leading-none mb-1">
              How can we help you?
            </div>
            <CtmPhone
              cssClasses="font-interbold text-medium-blue text-[30px] flex items-center gap-2 footer-phone no-underline"
              icon={phoneIcon}
            />
          </div>
        </div>
      </div>
      <div className="bg-dark-blue py-5 lg:py-10">
        <div className="container mx-auto text-white flex flex-col lg:flex-row items-center justify-center gap-3 lg:gap-6 text-[16px] leading-none">
          <span>FENIX FUNERAL LTD © 2019 - 2023</span>
          <span className="hidden lg:block">|</span>
          <a
            href="https://fenixfuneral.co.uk/pdf/Fenix_Terms_and_Conditions.pdf"
            className="underline"
          >
            TERMS & CONDITIONS
          </a>
          <span className="hidden lg:block">|</span>
          <a
            href="https://fenixfuneral.co.uk/pdf/Fenix_Complaints_Policy.pdf"
            className="underline"
          >
            COMPLAINTS POLICY
          </a>
          <span className="hidden lg:block">|</span>
          <a
            href="https://fenixfuneral.co.uk/pdf/Fenix_Privacy_Policy.pdf"
            className="underline"
          >
            PRIVACY POLICY
          </a>
        </div>
      </div>
    </main>
  );
}
