import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Checkout from "../components/Checkout";
import MainTitle from "../components/MainTitle";

export default function StatusPage() {
  const [title, setTitle] = useState("");
  const [clientSecret, setClientSecret] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    let title = t("payment-title-status");

    if (clientSecret === null || clientSecret === "") {
      title = t("payment-title-no-available");
    }

    setTitle(title);
    document.title = `${title} - Fenix`;
  }, [title]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const cs = params.get("payment_intent_client_secret");
    setClientSecret(cs);
  }, []);

  return (
    <>
      <MainTitle title={title} />
      {clientSecret && <Checkout clientSecret={clientSecret} />}
    </>
  );
}
