{
  "contracts": "Kontrakt",
  "back": "Tillbaka",
  "start": "Starta",
  "in-progress": "Pågående",
  "finished": "Färdiga",
  "complete-btn": "Godkänt",
  "restart-btn": "Ändra svar",
  "error": {
    "radio": "Du måste välja ett alternativ för att kunna gå vidare",
    "input": {
      "empty": "Det här fältet måste du fylla i för att kunna gå vidare",
      "short": "Du måste fylla i minst två tecken i fältet"
    },
    "range": "Du måste ange ett värde för att kunna gå vidare",
    "personnummer": "Fyll i med 12 siffror, XXXXXXXX-XXXX",
    "email": "Det verkar vara fel e-postadress. Försök igen",
    "phone": "Är det rätt telefonnummer? Försök igen"
  }
}