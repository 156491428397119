import React, { useLayoutEffect } from "react";

import { getPhoneNumber } from "../services/utils";

export default function CtmPhone({ cssClasses = "", icon = null }) {
  const [phone, phoneFull, replacePhoneCTM] = getPhoneNumber();

  useLayoutEffect(() => {
    replacePhoneCTM();
  }, []);

  return (
    <a className={`underline ${cssClasses}`} href={`tel:${phoneFull}`}>
      {icon ? (
        <>
          <img src={icon.href} alt="" />
          <p>{phone}</p>
        </>
      ) : (
        phone
      )}
    </a>
  );
}
