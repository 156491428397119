import "./i18n";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    release:
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      process.env.npm_package_name + "@" + process.env.npm_package_version,
  });
}

const baseurl = process.env.BASEURL;

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <BrowserRouter basename={baseurl}>
    <App />
  </BrowserRouter>
);
