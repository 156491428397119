import React from "react";
import { Route, Routes } from "react-router-dom";

import LayoutHomepage from "./components/LayoutHomepage";
import CheckoutPage from "./pages/CheckoutPage";
import StatusPage from "./pages/StatusPage";

export default function App() {
  return (
    <Routes>
      <Route element={<LayoutHomepage />}>
        <Route path="/" element={<CheckoutPage />} />
        <Route path="/status" element={<StatusPage />} />
      </Route>
    </Routes>
  );
}
