const apiUrl: string = process.env.IDA_API_URL;

export async function getToken(pi: string, id: string) {
  const response = await fetch(
    `${apiUrl}/public/payment/stripe/clientsecret?pi=${pi}&oid=${id}`,
    {
      method: "GET",
    }
  ).catch((error: string) => {
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
}
