{
  "payment-title": "Make your payment",
  "payment-title-no-available": "Payment not possible",
  "payment-title-no-available-desc": "Either the payment has already been made, or the link you clicked has expired.",
  "payment-title-no-available-desc-contact": "If you’re not sure if a payment has been made correctly, or have any other questions, feel free to contact us:",
  "payment-title-status": "Your payment status",
  "payment-description": "Thank you for trusting us with your order.",
  "payment-pay-button": "Pay",
  "payment-status-success": "Thank you, the payment has been approved, and your order is now complete.",
  "payment-status-processing": "Your payment is being processed.",
  "payment-status-try-again": "Your payment was not successful, please try again.",
  "payment-status-unknown": "Sorry, something went wrong.",
  "payment-status-already-succeeded": "Your payment is already paid."
}