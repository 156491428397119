import "../assets/styles/stripe.scss";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import React from "react";
import { useTranslation } from "react-i18next";

import CheckoutForm from "./CheckoutForm";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(publishableKey);

export default function App({
  clientSecret,
  orderInfo,
  updateTitle,
}: {
  clientSecret: string;
}) {
  const options: StripeElementsOptions = {
    clientSecret: clientSecret,
    appearance: {
      theme: "stripe",
      rules: {
        ".Input": {
          borderColor: "rgba(0,39,51,0.5)",
        },
        ".Input::placeholder": {
          color: "rgba(0,39,51,0.75)",
        },
        ".Label": {
          color: "#002733",
          fontWeight: 500,
          marginBottom: "10px",
        },
      },
    },
    locale: "en", // sv, en
  };

  return (
    <div className="text-white mb-10 max-w-full sm:max-w-[500px]">
      <div className="checkout-form">
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm
              cs={clientSecret}
              orderInfo={orderInfo}
              updateTitle={updateTitle}
            />
          </Elements>
        )}
      </div>
    </div>
  );
}
