import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import LoaderIcon from "./LoaderIcon";

export default function CheckoutForm({
  cs,
  orderInfo,
  updateTitle = () => null,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setSuccessful] = useState(false);
  const [fetchingStatus, setFetchingStatus] = useState(true);

  useEffect(() => {
    if (isSuccessful) {
      updateTitle(t("payment-title-status"));
    }
  }, [isSuccessful]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (clientSecret) {
      stripe
        .retrievePaymentIntent(clientSecret)
        .then(({ paymentIntent }) => {
          switch (paymentIntent?.status) {
            case "succeeded":
              setSuccessful(true);
              setMessage(t("payment-status-success"));
              break;
            case "processing":
              setMessage(t("payment-status-processing"));
              break;
            case "requires_payment_method":
              setMessage(t("payment-status-try-again"));
              break;
            default:
              setMessage(t("payment-status-unknown"));
              break;
          }
        })
        .catch((error: Error) => {
          console.log(error.message);
        })
        .finally(() => {
          setFetchingStatus(false);
        });
    } else {
      stripe
        .retrievePaymentIntent(cs)
        .then(({ paymentIntent }) => {
          if (paymentIntent?.status === "succeeded") {
            setMessage(t("payment-status-already-succeeded"));
            setSuccessful(true);
          }
        })
        .catch((error: Error) => {
          console.log(error.message);
        })
        .finally(() => {
          setFetchingStatus(false);
        });
    }
  }, [stripe]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.origin + "/status",
      },
      // redirect: "if_required",
    });

    console.log("STRIPE RESP", response);

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (response.error && response.error.message) {
      setMessage(response.error.message);
    }

    setIsLoading(false);
  };

  if (isSuccessful) {
    return (
      <div>
        <div className="mb-10">{message}</div>
        <div className="font-pprightgothic font-interbold text-[24px] mb-5">
          Your opinion matters
        </div>
        <div className="">
          People all over the UK trust us to support them through those
          challenging moments of life and loss. If you’re satisfied with Fenix,
          keep an eye on your inbox – we’ll soon send you an email that will
          offer you the chance to leave a review.
        </div>
      </div>
    );
  }

  return (
    <>
      {fetchingStatus ? (
        <LoaderIcon size="w-6 h-6" />
      ) : (
        <>
          <p className="mb-5">{t("payment-description")}</p>
          {orderInfo.orderId ? (
            <p className="text-[14px]">
              Order number:{" "}
              <span className="font-interbold">{orderInfo.orderId}</span>
            </p>
          ) : null}
          {orderInfo.orderTotalPriceFormatted ? (
            <p className="text-[14px] mb-6">
              Price:{" "}
              <span className="font-interbold">
                {orderInfo.orderTotalPriceFormatted}
              </span>
            </p>
          ) : null}
          <div className="bg-white rounded-xl lg:inline-block">
            <form
              id="payment-form"
              className="!p-4 lg:!p-8"
              onSubmit={(e) => {
                void handleSubmit(e);
              }}
            >
              <PaymentElement id="payment-element" />
              <button
                disabled={isLoading || !stripe || !elements}
                id="submit"
                className="!bg-dark-blue !text-white"
              >
                <span id="button-text">
                  {isLoading ? (
                    <div className="flex justify-center w-full">
                      <LoaderIcon size="w-6 h-6" />
                    </div>
                  ) : (
                    t("payment-pay-button")
                  )}
                </span>
              </button>
              {message !== "" && <div id="payment-message">{message}</div>}
            </form>
          </div>
        </>
      )}
    </>
  );
}
